import * as React from 'react'
import styled from 'styled-components'

import {noop} from '@settleindex/fp'
import * as testIds from '@settleindex/testids'

import {secondaryNavHeight} from '../secondary/secondaryNavHeight'
import {MenuSection} from './MenuSection'
import type {PrimaryMenuItem} from './PrimaryMenuItem'
import {PrimaryNavLayout} from './PrimaryNavLayout'

import {Link} from '#lib/link/Link.js'
import {LogoImg} from '#lib/logo/LogoImg.js'
import logoPng from '#lib/logo/logo_white.png'
import {contentVerticalPadding} from '#lib/page/consts.js'
import {paths} from '#lib/routing/paths.js'

const LogoSection = styled.div`
  display: flex;
  height: ${secondaryNavHeight}px;
  align-items: center;
  padding-left: 18px;
  margin-bottom: ${contentVerticalPadding}px;
`

const BottomSection = styled.div`
  padding-bottom: 10px;
`

interface Props {
  bottomMenuItems: PrimaryMenuItem[]
  expanded: boolean
  topMenuItems: PrimaryMenuItem[]
}

export const PrimaryNav: React.FC<Props> = ({bottomMenuItems, expanded, topMenuItems}) => {
  const [layoutExpanded, setLayoutExpanded] = React.useState<boolean>(expanded)
  React.useEffect(() => {
    setLayoutExpanded(expanded)
  }, [expanded])

  const onMouseEnter = expanded ? noop : () => setLayoutExpanded(true)
  const onMouseLeave = expanded ? noop : () => setLayoutExpanded(false)

  return (
    <PrimaryNavLayout
      data-test-id={testIds.primaryNav.primaryNav}
      expanded={layoutExpanded}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div>
        <LogoSection>
          <Link data-test-id={testIds.logo} to={paths.disputes()}>
            <LogoImg src={logoPng} />
          </Link>
        </LogoSection>
        <MenuSection expanded={layoutExpanded} menuItems={topMenuItems} />
      </div>
      <BottomSection>
        <MenuSection expanded={layoutExpanded} menuItems={bottomMenuItems} />
      </BottomSection>
    </PrimaryNavLayout>
  )
}
