import styled from 'styled-components'

import * as testIds from '@settleindex/testids'
import logoPng from '../logo/logo_dark.png'

const Style = styled.img`
  width: 50px;
`

export const Logo = () => <Style data-test-id={testIds.logo} src={logoPng} />
